import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { SessionService } from '../../shared/services/session.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';
import { LoginRequest } from '../../shared/models/authentication/auth.model';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { CookieOptions, CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnDestroy, OnInit {
  loginData: LoginRequest = {};
  loginSubscription$: Subscription = new Subscription();
  passwordValidationSubscription$: Subscription = new Subscription();
  isPasswordCorrect = true;
  resetPasswordUrl: string = environment.RESET_PASSWORD;
  private readonly _destroying$ = new Subject<null>();
  loginStatus = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private sessionService: SessionService,
    private spinnerService: SpinnerService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.spinnerService.showSpinner();
    this.authenticationService.isPasswordCorrect$.subscribe((data) => {
      this.isPasswordCorrect = data;
    });

    if(this.authenticationService.isMsalLoggedIn()) {
      sessionStorage.setItem('isAuthLoading', 'true');
    }
    else {
      if(!sessionStorage.getItem('isAuthLoading')) {
        sessionStorage.setItem('isAuthLoading', 'false');
      }
      if(sessionStorage.getItem('isAuthLoading') === 'true') this.spinnerService.showSpinner();
      else this.spinnerService.hideSpinner();
    }

    this.msalService.handleRedirectObservable().subscribe({
      next: (result) => {
        if (!result) {
          this.setSessionStorageAndLoader('false');
        }
      },
      error: (err) => {
        this.setSessionStorageAndLoader('false');
      }
    });

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe((result: EventMessage) => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        this.router.navigate(['/login']);
      } else {
        this.setLoginDisplay();
      }
    });

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
  }

  onSubmit(loginForm: NgForm): void {
    if (loginForm.valid) {
      this.spinnerService.showSpinner();
      this.loginSubscription$ = this.authenticationService.login(this.loginData).subscribe(
        () => {
        this.sessionService.updateAuthStatus(true, this.loginData);
        this.sessionService.isLoggedIn();
        this.router.navigate(['dashboard']);
      });
    }
  }

  ssoLogin() {
    this.setSessionStorageAndLoader('true');
    try {
      this.authenticationService.loginSso();
    } catch(error) {
      this.setSessionStorageAndLoader('false');
    }
  }

  async setLoginDisplay() {
    this.loginStatus = this.msalService.instance.getAllAccounts().length > 0;

    if(this.loginStatus){
      const profile_data = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(profile_data);
      
      try {
        if(!this.authenticationService.isMsalLoggedIn()) {
          this.setMsalToken();
        }
        else {
          this.loginUsingSso();
        }
      } catch(error) {
        this.setSessionStorageAndLoader('false');
      }
    } else {
      if(this.authenticationService.isMsalLoggedIn()) {
        await this.ssoLogin();

        this.setSessionStorageAndLoader('false');
      }
      this.router.navigate(['/login']);
    }
  }

  setMsalToken() {
    const tokenRequest = {
        scopes: ['user.read'],
        account: this.msalService.instance.getAllAccounts()[0]
    }
    this.msalService.acquireTokenSilent(tokenRequest).subscribe((tokenResponse) => {
        if (tokenRequest != null) {
          const domain = this.getDomainFromURL(window.location.hostname);
          const expiry = new Date((new Date()).getTime() + 3600 * 1000);
          let cookieOptions: CookieOptions = {
            domain: domain,
            secure: true,
            sameSite: 'None',
            expires: expiry
          }
          this.cookieService.set(
            'msal-access-token', 
            JSON.stringify({token: tokenResponse.accessToken, expiry: expiry}), cookieOptions
          );
          this.cookieService.set(
            'msal-id-token', 
            JSON.stringify({token: tokenResponse.idToken, expiry: expiry}), cookieOptions
          );

          this.loginUsingSso();
        }
    })
  }

  loginUsingSso() {
    this.authenticationService.loginUsingSso({
      accessToken: JSON.parse(this.cookieService.get('msal-access-token')).token,
      idToken: JSON.parse(this.cookieService.get('msal-id-token')).token,
      email: this.msalService.instance.getActiveAccount()?.username!
    }).subscribe({
      next: () => {
        this.setSessionStorageAndLoader('false');
        this.router.navigate(['/dashboard/admin']);
      },
      error: () => {
        this.setSessionStorageAndLoader('false');
      }
    });
  }

  private getDomainFromURL(url: string) {
    const parts = url.split('.');
    if(parts.length > 2) 
      return parts.slice(-2).join('.');
    else
      return parts[0];
  }

  private setSessionStorageAndLoader(value: string) {
    if(value === 'true') {
      this.spinnerService.showSpinner();
    } else {
      this.spinnerService.hideSpinner();
    }
    sessionStorage.setItem('isAuthLoading', value);
  }

  ngOnDestroy(): void {
    this.loginSubscription$?.unsubscribe();
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
